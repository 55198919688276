.yourShop-container {
    width: var(--width);
    max-width: var(--max-width);
    margin: auto;
    padding: 2rem 0;
}

.products-card {
    display: flex;
    align-items: center;
    justify-content: center;
}


/* Large devices (laptops/desktops) */
@media only screen and (max-width: 992px) {
    .products-card {
        flex-wrap: wrap;
    }
}