nav {
    background-color: #101539;
    position: relative;
}

nav .nav-container {
    width: var(--width);
    max-width: var(--max-width);
    margin: auto;
    display: flex;
    justify-content: space-between;
    padding: 5px 0;
}

nav svg {
    width: 30px;
    position: relative;
    right: 0;
    transform: rotate(180deg);
    display: none;
    cursor: pointer;
}

nav a {
    padding: 1rem 0;
    color: #eee;
}

nav a.active {
    color: #fff;
    border-bottom: 2px solid #fff;
}

nav a:hover {
    border-bottom: 2px solid #fff;
}

@media only screen and (max-width: 600px) {
    nav {
        display: flex;
        justify-content: flex-end;
    }

    nav svg {
        display: block;
        transition: transform 0.7s;
    }

    nav .nav-container {
        position: absolute;
        flex-direction: column;
        background-color: #fefefe;
        max-width: max-content;
        left: -100%;
        top: 0;
        padding: 0 10px;
        border-bottom-right-radius: 10px;
        box-shadow: 0 0 10px #fff;
        transition: left 0.7s;
        z-index: 20;
    }

    nav .nav-container a {
        color: #101539;
        padding: 10px 0;
    }

    nav.display .nav-container {
        left: 0;
    }

    nav.display svg {
        transform: rotate(0deg);
    }

}