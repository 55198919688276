.vos-services-container {
    height: 60vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.vos-services-container .wrapper {
    display: grid;
    grid-template-columns: repeat(2, 250px);
    justify-content: center;
    align-items: center;
    gap: 2rem;
}

.vos-services-container a {
    padding: 1rem 0;
    box-shadow: 0px 0px 3px #cbcef0;
    background-color: #cbcef0;
    text-align: center;
    font-size: 21px;
    font-weight: 700;
    color: #101539; 
    transition: box-shadow 0.3s;
}

.vos-services-container a:hover {
    box-shadow: 0px 0px 3px #929bfa;
}

@media (max-width: 768px) {

    .vos-services-container .wrapper {
        grid-template-columns: repeat(1, 250px);
    }
}