:root {
    --b-input: 1px solid #323a74;
    --b-input-d: 1px dashed #323a74;
}

/* general */
.edit-profile-container input, .edit-profile-container select {
    background: transparent;
    border: var(--b-input);
    color: #cdd2fc;
    padding: 3px 10px;
    max-width: 200px;
}

/* general */

.edit-profile-container {
    background: #101539;
    max-width: var(--max-width);
    margin: 30px auto;
    color: #fff;
    width: var(--width);
    border-radius: 10px;
}

.edit-profile-container .edit-img {
    display: flex;
    align-items: center;
    padding: 1rem 0 0 30px;
}

.edit-profile-container .edit-img img {
    width: 100px;
    margin-right: 10px;
}

.edit-profile-container .edit-img span {
    position: relative;
    text-decoration: underline;
    cursor: pointer;
    overflow: hidden;
}

.edit-profile-container .edit-img span input {
    display: none;
}

.edit-profile-container .title {
    border-bottom: 1px solid #323a74;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 30px 5px;
}

.profile-btn {
    border: 0;
    padding: 10px 2rem;
    background: #fff;
    color: #101539;
    font-weight: 600;
    cursor: pointer;
}

.edit-profile-container .details-content {
    padding-left: 30px;
    border-bottom: 1px solid #323a74;
}

.edit-profile-container .form-group {
    margin: 1rem 0;
}

.details-content label {
    display: inline-block;
    min-width: 120px;
}

.edit-profile-container .password {
    display: grid;
    grid-template-columns: repeat(4, minmax(50px, 1fr));
    align-items: center;
    gap: 1rem;
}

.edit-profile-container .password button {
    padding: 5px;
    margin: 5px;
    max-width: 200px;
    background: #101539;
    color: #cdd2fc;
    border: var(--b-input);
}

.edit-profile-container .password button:hover {
    background: #20296d;
}

/*  Salooooooooooooon section */

.edit-salon .salon-content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-items: baseline;
    border-bottom: 0;
    padding-left: 30px;
}

.working-hours .wrapper>div {
    padding: 10px 0;
}

.working-hours .wrapper button {
    padding: 0;
    background-color: transparent;
    border: none;
    margin-left: 4px;
    background: url("../../mark.svg") 0 0 / 25px 25px no-repeat;
    width: 25px;
    height: 25px;
    vertical-align: middle;
}

.working-hours .wrapper button svg {
    vertical-align: middle;
}

/*dialog*/
.working-hours  .dialog {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.384);
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    display: none;
}

.working-hours .dialog p {
    background-color: #fff;
    color: #101539;
    padding: 40px;
    border-radius: 7px;
}

.working-hours  .display-dialog {
    display: flex;
}

.working-hours .wrapper>div:not(.working-hours .wrapper>div:last-child) {
    border-bottom: var(--b-input-d);
}

.salon-content label {
    display: inline-block;
    min-width: 120px;
}

.working-hours .wrapper>div select:nth-of-type(2) {
    margin-left: 20px;
}

/* right part */
.edit-salon .salon-content>div:nth-child(2) label {
    display: inline-block;
    min-width: 170px;
}

.edit-salon .salon-content>div:nth-child(2) select {
    width: 200px;
    box-sizing: content-box;
}

.add-salon-btn {
    text-align: right;
}

.add-salon-btn button {
    margin: 30px;
}

/* Large devices (laptops/desktops) */
@media only screen and (max-width: 992px) {
    .edit-profile-container {
        width: 95%;
    }

    .edit-profile-container .details-content,
    .edit-salon .salon-content,
    .edit-profile-container .edit-img,
    .edit-profile-container .title {
        padding-left: 15px;
    }

    .edit-salon .salon-content>div:nth-child(2) {
        justify-self: center;
    }

    .edit-profile-container input, .edit-profile-container select {
        padding: 3px 5px;
        max-width: 150px;
    }
}

@media (max-width: 768px) {

    .edit-profile-container .details-content,
    .edit-salon .salon-content,
    .edit-profile-container .edit-img,
    .edit-profile-container .title {
        padding-left: 30px;
    }

    .edit-profile-container .password {
        grid-template-columns: repeat(2, minmax(50px, 1fr));
        align-items: center;
    }

    .edit-profile-container .password button {
        max-width: 150px;
        padding: 3px 5px;
        box-sizing: content-box;
        margin: 0;
    }

    .edit-salon .salon-content {
        grid-template-columns: 1fr;
        justify-items: baseline;
    }

    .edit-salon .salon-content>div:nth-child(2) {
        justify-self: baseline;
    }

    .edit-profile-container .edit-img img {
        width: 80px;
    }

}

/* Small devices (portrait tablets and large phones) */
@media only screen and (max-width: 600px) {

    .edit-profile-container .details-content,
    .edit-salon .salon-content,
    .edit-profile-container .edit-img,
    .edit-profile-container .title {
        padding-left: 10px;
    }

    .edit-profile-container .title {
        padding-right: 10px;
    }

    .edit-profile-container h3 {
        font-size: 16px;
    }

    .edit-profile-container h4 {
        font-size: 14px;
    }

    .profile-btn {
        padding: 5px 1rem;
    }

    .salon-content .wrapper label {
        min-width: 80px;
    }

    .details-content label,
    .salon-content label {
        font-size: 15px;
    }

    .edit-salon .salon-content>div:nth-child(2) label {
        min-width: 155px;
    }

    .add-salon-btn button {
        margin: 30px 10px;
    }
}

.app-room-map-modal-content {
    width: 80%;
    margin: auto;
}

.app-update-location-button {
    display: block;
    margin: 20px auto 2px auto;
}