:root {
  --f-dark: #222;
}

footer {
  background-color: #afb8e4;
  position: relative;
  padding: 5rem 0 2.5rem 0;
  margin-top: 3rem;
}

footer .footer-container {
  width: var(--width);
  max-width: var(--max-width);
  margin: auto;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 3rem;
  margin-bottom: 2.5rem;
}

footer .footer-container h5 {
  font-size: 15px;
  padding-bottom: 10px;
  border-bottom: 1px solid var(--f-dark);
  margin-bottom: 10px;
}

footer .footer-container h5 svg {
  display: none;
}

footer svg {
  vertical-align: middle;
  margin-left: 5px;
}

/* about */
footer .about p {
  font-size: 14px;
  color: var(--f-dark);
}

/* social-media */
footer .social-media div, footer .social-top div {
  display: flex;
  justify-content: space-evenly;
}

footer .social-media img, footer .social-top div img {
  width: 30px;
  cursor: pointer;
}

/* contact */
footer .contact a {
  display: block;
  margin: 10px 0;
  font-size: 14px;
  color: var(--f-dark);
}

footer .contact a svg {
  width: 20px;
  margin-right: 10px;
}

/* app */

.app div {
  text-align: center;
}

.app div a:nth-of-type(2) {
  display: block;
  margin-top: 1rem;
}

.app div img {
  max-width: 150px;
}


footer hr {
  color: transparent;
  border-bottom: #000 1px solid;
}

footer .copyright,
footer .social-top {
  display: none;
}

/* Large devices (laptops/desktops) */
@media only screen and (max-width: 992px) {
  footer .footer-container {
    gap: 1rem;
    width: 95%;
  }

  footer .contact a svg {
    margin-right: 5px;
    margin-left: 0;
  }
}

@media only screen and (max-width: 768px) {
  :root {
    --f-dark: #fff;
  }

  footer {
    background-color: #101539;
    color: #fff;
  }

  footer .footer-container {
    width: 90%;
    grid-template-columns: 1fr;
  }

  footer .footer-container h5 {
    border-color: #fff;
  }

  footer .footer-container h5 svg {
    display: inline;
  }

  footer .social-media {
    display: none;
  }

  footer .social-top {
    display: block;
  }

  footer .social-top div {
    justify-content: flex-start;
    margin-top: 1rem;
  }

  footer .social-top div img {
    margin-right: 2rem;
  }

  .footer-container div:not(.social-top) div {
    max-height: 0px;
    overflow: hidden;
    transition: max-height 0.3s;
  }
  .footer-container >div>div.display-content{
    max-height: max-content;
  }
  footer .copyright {
    display: block;
    text-align: left;
    text-transform: uppercase;
    font-weight: 600;
  }

  footer .copyright span.year {
    font-size: 16px;
  }

  footer hr {
    border-color: #fff;
  }
}