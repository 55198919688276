.services-container {
    width: 80%;
    margin: 30px auto;
    position: relative;
}

.services-container .add-service-btn {
    text-align: right;
}

.services-container .add-service-btn button {
    padding: 1rem 3.5rem;
    font-size: 20px;
    font-weight: bold;
    background-color: #a6b1e1;
    color: #fff;
    border-radius: 3px;
    border: 0;
    position: relative;
    text-transform: uppercase;
}


.services-container .add-service-btn button:after {
    content: "";
    position: absolute;
    top: 50%;
    right: 4%;
    transform: translateY(-50%);
    border-bottom: 8px solid transparent;
    border-top: 8px solid transparent;
    border-left: 15px solid white;
    border-right: 8px solid transparent;
}

.services {
    margin: 20px auto;
    box-shadow: 0 0 15px 0 rgb(0 0 0 / 30%);
    min-height: max-content;
}

.services .title-bar {
    padding: 1rem 0;
    background-color: #101539;
    display: flex;
    align-items: center;
}

.services .title-bar .squares {
    margin: 0 20px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.services .title-bar .squares div {
    margin-right: 5px;
}


.services .title-bar .title {
    color: #fff;
    font-size: 25px;
    font-weight: bold;
}

.service-content {
    max-height: max-content;
    overflow-y: auto;
}

/* service component */


.service {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    border-bottom: 1px solid #10153930;
    background-color: #fff;
}

.service:last-child {
    border-bottom: 0;
}

.service .image {
    position: relative;
}

.service .image img {
    width: 200px;
    height: 200px;
    object-fit: contain;
}

.service .image div div {
    background-color: #fff;
    width: 5px;
    height: 5px;
    display: inline-block;
}

.service .image div div.active {
    background-color: #a6b1e1;
}

.service .price,
.service .service-info {
    margin-top: 20px;
    margin-left: 20px;
}

.service .price h4,
.service .service-info h4 {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 10px;
    margin-top: 0;
}

.service .service-info h5,
.service .price h5 {
    font-family: "Nunito", sans-serif;
    font-size: 16px;
    font-weight: 600;
    color: rgba(18, 19, 27, 0.5);
    margin-top: 0;
}



.service .service-control {
    padding-right: 30px;
    display: flex;
    align-items: center;
    position: relative;
    align-self: center;
}


.service .options {
    cursor: pointer;
    margin-left: 5px;
}

.service .options img {
    width: 30px;
    margin-right: 5px;
    cursor: pointer;
}


/* edit... popup */
.control-options {
    position: absolute;
    box-shadow: 0 0 20px 0 rgb(0 0 0 / 20%);
    right: 100%;
    padding: 1rem 3rem 1rem 1rem;
    background-color: #fff;
    border-radius: 10px;
}

.control-options::after {
    content: "";
    position: absolute;
    top: calc(50% - 18px);
    right: -38px;
    border: 20px solid;
    border-color: transparent transparent transparent #fff;
    pointer-events: none;
}

.control-options div {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.service-control .control-options img {
    width: 21px;
}

.control-options span {
    font-size: 19px;
    font-weight: 600;
    color: rgba(18, 19, 27, 0.5);
    margin-left: 20px;
}

.control-options div:nth-child(2) {
    margin: 20px 0;
}

/* Large devices (laptops/desktops) */
@media only screen and (max-width: 992px) {
    .services-container {
        width: 90%;
    }

    .services-container .add-service-btn button {
        padding: 1rem 2.5rem 1rem 2rem;
        font-size: 1rem;
    }

    .services .title-bar .title {
        font-size: 20px;
    }

}

@media only screen and (max-width: 768px) {
    .services-container {
        width: 95%;
    }

    .service {
        padding-bottom: 15px;
    }

    .services-container .add-service-btn {
        text-align: center;
    }

    .services-container .add-service-btn button {
        width: 100%;
        font-size: 14px;
    }

    .service .service-control {
        padding-right: 10px;
    }
}

/* Small devices */
@media only screen and (max-width: 600px) {
    .service {
        flex-direction: column;
        align-items: center;
        position: relative;
    }

    .service .image img {
        width: 150px;
        height: 150px;
        object-fit: contain;
    }

    .service .price, .service .service-info {
        margin-top: 10px;
        text-align: center;
    }

    .service .service-control {
        position: absolute;
        right: 0;
    }

    .control-options {
        top: 200%;
        right: 10px;
        box-shadow: 0 -5px 30px 0 rgba(0, 0, 0, 0.2);
        border-radius: 10px 0 10px 10px;
        padding: 1rem;
    }

    .control-options::after {
        top: -40px;
        right: 0;
        transform: rotate(-90deg);
    }

    .service-control .control-options img {
        width: 16px;
    }

    .control-options span {
        font-size: 16px;
    }
}