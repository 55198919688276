:root {
    --border: 1px dashed #aaa;
}

/* //override some styles */
.general-info .info {
    align-items: normal;
}

.add-service-img, .principal-img {
    flex-grow: 0.5;
    height: 320px;
    width: auto;
    max-width: 230px;
    padding: 10px;
    border: var(--border);
}

.principal-img {
    display: flex;
    align-items: center;
    border-radius: 10px;
    overflow: hidden;
}

.principal-img img {
    width: 100%;
    max-width: 300px;
}

.add-service-img span,
.edit-service-img span {
    background: #fefefe;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    height: 100%; 
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}

.add-service-img span::before,
.edit-service-img span::before,
.add-service-img span::after,
.edit-service-img span::after {
    content: "";
    position: absolute;
    width: 10px;
    height: 70px;
    top: 50%;
    border: 1px dashed #777;
}

.add-service-img span::before,
.edit-service-img span::before {
    left: 50%;
    transform: translate(-50%, -50%);
}

.add-service-img span::after,
.edit-service-img span::after {
    left: 50%;
    transform: translate(-50%, -50%) rotate(90deg);
}

.general-info input[type=file] {
    display: none;
}

.availabel-imgs {
    display: flex;
    flex-wrap: wrap;
    margin-left: 60px;
}

.availabel-imgs div {
    position: relative;
    width: 200px;
    height: 200px;
    border: 1px dashed #aaa;
    margin: 0 10px 10px 0;
    padding: 10px;
    border-radius: 10px;
    background: #efefef;
}

.availabel-imgs img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.availabel-imgs .img-container span {
    width: 20px;
    height: 20px;
    position: absolute;
    left: 0;
    top: 0;
    background: red;
    cursor: pointer;
}

.availabel-imgs .img-container span::before,
.availabel-imgs .img-container span::after {
    content: "";
    width: 80%;
    height: 10%;
    background: #fff;
    position: absolute;
    left: 50%;
    top: 50%;
}

.availabel-imgs .img-container span::before {
    transform: translate(-50%, -50%) rotate(45deg);
}

.availabel-imgs .img-container span::after {
    transform: translate(-50%, -50%) rotate(-45deg);
}