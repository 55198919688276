.products-card .product-item {
    text-align: center;
    font-size: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    padding: 1.5rem 1rem;
    background-color: #fff;
    border-radius: 10px;
    width: 250px;
    margin:1rem ;
}

.products-card .product-item img {
    width: 68%;
}

.product-item h3 {
    padding-top: 1rem;
    border-top: 1px solid rgba(0, 0, 0, 0.3);
}

.products-card .product-item p {
    font-weight: 300;
}

.products-card .product-item span {
    font-weight: bold;
    padding-top: 10px;
    display: block;
}